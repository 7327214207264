import React from 'react';
import './css/VerExperiencias.css';

const experiencias = [
    {
        video: '/images/videos/Partners Seguros Rondanelli.mp4',
        enlace: '#', // Asegúrate de agregar el enlace correspondiente
    },
];

function VerServicios() {
    return (
        <div className="card-experiencias">
            <div className="containerVerServicios">
                <p className='descripcion2'>NUESTROS SOCIOS ESTRATÉGICOS</p>
            </div>
            <div className="filaExperienciaTipos">
                {experiencias.map((experiencia, index) => (
                    <a key={index} href={experiencia.enlace}>
                        <div className="columna-tipo-experiencias">
                            <div className='w-6/12 w-auto m-4 mb-20'>
                                <video
                                    src={experiencia.video}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    className='videoExperiencia'
                                />
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default VerServicios;