import React, { useState, useRef } from 'react';
import './css/ImageSlider.css';
import imagen6_Inicio from '../../images/Experiencia/Imagen6_Inicio.jpeg';
import logo from '../../images/Logos/LogoSidebar.png';

const ImageSlider = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
      videoRef.current.loop = true; // Asegurar que el video esté en bucle
      videoRef.current.play(); // Reproducir el video una vez cargado
    }
    setVideoLoaded(true);
  };

  const imagen = imagen6_Inicio;
  const texto = 'Encuentra aquí';
  const textoExperiencia = 'Seguros de protección, financieros y previsionales ';
  const SliderOverlay = 'TextoExperiencias';

  return (
    <div className="image-slider">
      <div className='overlay'></div> {/* Opacidad de los videos */}
      <div className='Fondo'></div>
      <div className='w-screen h-screen object-cover'>
        <video
          ref={videoRef}
          className='w-screen h-screen object-cover'
          src='/images/videos/hotel.mp4'
          autoPlay
          muted
          loop
          controls={false}
          playsInline
          onLoadedData={handleVideoLoaded}
        >
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={SliderOverlay}>
        <div className='flex justify-center'>
          <img src={logo} alt="Logo" />
        </div>
        <p>{texto}</p>
        <p className='Destacado'>{textoExperiencia}</p>
      </div>
    </div>
  );
};

export default ImageSlider;