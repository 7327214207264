import React from 'react';
import './css/WelcomeDescription.css'; // Asegúrate de tener este archivo CSS en la misma carpeta

const WelcomeDescription = () => {
  return (
    <div className="welcome-description">
      <div className='column-welcome'>
        <div className="text-row">
          <div className='Titulo-1-Inicio text-XL'>
            CORREDORA DE SEGUROS RONDANELLI
          </div> 
          <div className='Titulo-2-Inicio'> 
            
          </div>
        </div>
      </div>
      <div className='column-welcome'>
        <div className="text-row">
          <div className='Texto-Bienvenida text-justify'>
            <p className='text-sm font-light'>Nuestro equipo, liderado por <span className='font-bold'>Alfonso Rondanelli</span>, ofrece una amplia gama de servicios y productos diseñados para satisfacer diversas necesidades de protección y seguridad financiera tanto de personas como de empresas. Esto incluye seguros de vida y generales, basados ​​en certificaciones otorgadas por la Comisión para el Mercado Financiero (CMF) y respaldados por pólizas de garantía y responsabilidad civil actualizadas. Contamos, además, con sólidos conocimientos en materias previsionales bajo el DL 3.500, adquiridos a lo largo de más de 20 años de experiencia.</p>
            
          </div>
        </div>


        

      </div>

    </div>
  );
};

export default WelcomeDescription;