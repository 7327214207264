import React from 'react';
import './css/ButtonReservarHeader.css'; // Asegúrate de tener este archivo CSS en la misma carpeta

const ButtonReservarHeader = () => {
  const handleClick = () => {
    window.open('https://wa.me/56994337091', '_blank');
  };
  return (
    <button className="ButtonReservarHeader" onClick={handleClick}>
      HABLEMOS
    </button>
  );
};

export default ButtonReservarHeader;