import React, { useEffect, useState } from 'react';
import './css/inicio.css';
import Header from '../components/Header/header';
import Slide from '../components/Card/ImageSlider';
import WelcomeDescription from '../components/Card/WelcomeDescription';
import Footer from '../components/Footer/Footer';
import VerExperiencias from '../components/Card/VerExperiencias';
import VerPartners from '../components/Card/VerPartners';
import { useWindowScroll } from '@uidotdev/usehooks';



const Inicio = () => {
  const [{ y }] = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (y > 700) {
      setScrollPosition(1);
    } else {
      setScrollPosition(0);
    }
  }, [y]);



  return (
    <div className="contenedorInicio" >
      <Header scrollPosition={scrollPosition} className="fixed-header" />
      <div className="cuerpo">
        <Slide tipo="Inicio" />
        <div className="outer-div2" >
          <div className="inner-div2"  data-aos="fade-up">
            <WelcomeDescription />
          </div>
        </div>

        <div className='VerExperiencias' data-aos="fade-up">
          <VerExperiencias />
        </div>
        <div className='VerExperiencias' data-aos="fade-up">
          <VerPartners />
        </div>
        <div className='outer-div-Footer'>
          <Footer />
        </div> 
      </div>
    </div>
  );
};

export default Inicio;
