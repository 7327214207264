import React, { useState } from 'react';
import './css/VerExperiencias.css';
import Experiencia1 from '../../images/Experiencia/Experiencia1.png';
import Experiencia2 from '../../images/Experiencia/Experiencia2.png';
import Experiencia3 from '../../images/Experiencia/Experiencia3.png';
import Experiencia4 from '../../images/Experiencia/Experiencia4.png';
import Experiencia5 from '../../images/Experiencia/Experiencia5.png';
import Experiencia6 from '../../images/Experiencia/Experiencia6.png';
import Experiencia7 from '../../images/Experiencia/Experiencia7.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const experiencias = [
    {
        nombre: "Seguro de vida colectivo",
        imagen: Experiencia1,
        enlace: "/Experiencias/",
        coberturas: [
            "Fallecimiento natural o accidental",
            "Invalidez total y permanente 2/3",
            "Cobertura adicional por fallecimiento accidental",
            "Asistencia en caso de accidente"
        ]
    },
    {
        nombre: "Seguro de accidentes personales",
        imagen: Experiencia4,
        enlace: "/Experiencias/",
        coberturas: [
            "Protección para trabajadores en caso de accidentes.",
            "Respaldo económico a las familias por muerte accidental.",
            "Cobertura por invalidez total o parcial.",
            "Indemnización por lesiones graves o permanentes."
        ]
    },
    {
        nombre: "Seguro de responsabilidad civil pyme",
        imagen: Experiencia6,
        enlace: "/Experiencias/",
        coberturas: [
            "Protección frente a demandas por daños materiales a terceros.",
            "Cobertura por daños corporales a terceros.",
            "Resguardo del patrimonio del asegurado.",
            "Protección de la imagen y prestigio del asegurado.",
            "Cobertura para el causante del daño."
        ]
    },
    {
        nombre: "Seguro Automotriz",
        imagen: Experiencia3,
        enlace: "/Experiencias/",
        coberturas: [
            "Cobertura por daños en caso de robo o hurto.",
            "Responsabilidad civil por daños a terceros.",
            "Cobertura para daños materiales propios.",
            "Reembolso de gastos médicos en accidentes.",
            "Asistencia en carretera.",
            "Protección en colisiones y otros siniestros."
        ]
    },
    {
        nombre: "Seguro Complementario de salud pyme",
        imagen: Experiencia5,
        enlace: "/Experiencias/",
        coberturas: [
            "Reembolso de gastos médicos hospitalarios",
            "Cobertura para maternidad",
            "Reembolso de medicamentos",
            "Atención ambulatoria y consultas médicas",
            "Cobertura dental"
        ]
    },
    {
        nombre: "Seguros a comunidades de edificios",
        imagen: Experiencia2,
        enlace: "/Experiencias/",
        coberturas: [
            "Incendios por catástrofes",
            "Cristales, maquinarias, cañerías e instalaciones eléctricas",
            "Actos terroristas",
            "Robos en condominio",
            "Responsabilidad civil Premium",
            "Sismo",
            "Incendio por sismo",
            "Responsabilidad civil para administradores y comité"
        ]
    }
];

function VerServicios() {


    return (
        <div className="card-experiencias">
            <div className="containerVerServicios">
                <p className='descripcion2'>CONOCE NUESTROS SEGUROS</p>
            </div>
            <div className="filaExperienciaTipos">
                {experiencias.map((experiencia, index) => (
                    <div key={index} className="columna-tipo-experiencias">
                        <div className='ContenedorImagen'>
                            <div className='overlay'></div> {/* Capa de transparencia */}
                            <img src={experiencia.imagen} alt={experiencia.nombre} />
                            <p className='NombreExperiencia'><strong>{experiencia.nombre}</strong></p>
                        </div>
                            <div className='ContenedorImagen text-gray-700 text-sm text-justify p-4'>
                                <ul>
                                    {experiencia.coberturas.map((cobertura, i) => (
                                        <li key={i}>
                                            <FontAwesomeIcon className='text-green-700' icon={faCircleCheck} /> {cobertura}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VerServicios;